import { InternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import pluralize from "pluralize";

interface RateNegotiationsCardProps {
  activeNegotiationsCount: number;
  facilityProposalsCount: number;
  recentlyEndedNegotiationsCount: number;
  deepLinkNegotiationId?: string;
}

export function RateNegotiationsCard(props: RateNegotiationsCardProps) {
  const {
    activeNegotiationsCount,
    facilityProposalsCount,
    recentlyEndedNegotiationsCount,
    deepLinkNegotiationId,
  } = props;

  if (activeNegotiationsCount === 0 && recentlyEndedNegotiationsCount === 0) {
    return null;
  }

  const searchParams = new URLSearchParams();

  if (isDefined(deepLinkNegotiationId)) {
    searchParams.set("negotiationId", deepLinkNegotiationId);
  }

  return (
    <Card
      variant="outlined"
      sx={{
        backgroundColor: facilityProposalsCount > 0 ? "#f3fff8" : undefined,
      }}
    >
      <CardActionArea
        component={InternalLink}
        to={{
          pathname: DeprecatedGlobalAppV1Paths.RATE_NEGOTIATIONS,
          search: searchParams.toString(),
        }}
      >
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack>
              <Text variant="body2">
                {activeNegotiationsCount === 0
                  ? "No negotiating shifts"
                  : `Negotiating rates for ${activeNegotiationsCount} ${pluralize(
                      "shift",
                      activeNegotiationsCount
                    )}`}
              </Text>
              <Text>
                {facilityProposalsCount === 0
                  ? "No new Workplace proposals"
                  : `${facilityProposalsCount} Workplace ${pluralize(
                      "proposal",
                      facilityProposalsCount
                    )}`}
              </Text>
            </Stack>
            <ChevronRight color={facilityProposalsCount > 0 ? undefined : "disabled"} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
