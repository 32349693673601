import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { shiftSchema } from "../../Shift/types";

const useNextTwoDaysShiftsRequestSchema = z.object({
  tmz: z.string(),
});

const useNextTwoDaysShiftsResponseSchema = z.object({
  error: z.unknown(),
  response: z.array(shiftSchema),
  success: z.boolean(),
});

type UseNextTwoDaysShiftsResponse = z.infer<typeof useNextTwoDaysShiftsResponseSchema>;

export function useNextTwoDaysShifts(
  timezone: string,
  options: UseGetQueryOptions<UseNextTwoDaysShiftsResponse> = {}
): UseQueryResult<UseNextTwoDaysShiftsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/myShifts/nextTwoDays`,
    queryParams: {
      tmz: timezone,
    },
    requestSchema: useNextTwoDaysShiftsRequestSchema,
    responseSchema: useNextTwoDaysShiftsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_NEXT_TWO_DAYS_SHIFTS_FAILURE,
    },
    ...options,
  });
}
