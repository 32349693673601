import { useGetQuery } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { shiftSchema } from "../../Shift/types";

const useFutureShiftsRequestSchema = z.object({
  tmz: z.string(),
});

const useFutureShiftsResponseSchema = z.object({
  error: z.unknown(),
  response: z.array(shiftSchema),
  success: z.boolean(),
});

type UseFutureShiftsResponse = z.infer<typeof useFutureShiftsResponseSchema>;

export function useFutureShifts(
  timezone: string,
  options: UseQueryOptions<UseFutureShiftsResponse> = {}
): UseQueryResult<UseFutureShiftsResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/myShifts/future`,
    queryParams: {
      tmz: timezone,
    },
    requestSchema: useFutureShiftsRequestSchema,
    responseSchema: useFutureShiftsResponseSchema,
    meta: {
      // @ts-expect-error TS thinks GET_FUTURE_SHIFTS_FAILURE is unknown instead of string
      logErrorMessage: APP_V2_APP_EVENTS.GET_FUTURE_SHIFTS_FAILURE,
    },
    ...options,
  });
}
