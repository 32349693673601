import { InternalLink, Text } from "@clipboard-health/ui-react";
import { Card, CardActionArea, CardContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import pluralize from "pluralize";

interface PendingSentHomeRequestsCardProps {
  pendingSentHomeRequestsCount: number;
  totalEarnings: number;
  agentId?: string;
}

export function PendingSentHomeRequestsCard(props: PendingSentHomeRequestsCardProps) {
  const { pendingSentHomeRequestsCount, totalEarnings, agentId } = props;

  return (
    <Card variant="outlined">
      <CardActionArea
        component={InternalLink}
        to={DeprecatedGlobalAppV1Paths.PENDING_SENT_HOME_REQUESTS}
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.PENDING_SENT_HOME_REQUESTS_CARD_CLICKED, {
            agentId,
          });
        }}
      >
        <CardContent sx={{ padding: 3 }}>
          <Stack>
            {totalEarnings > 0 ? (
              <Text variant="body2">
                ${totalEarnings.toFixed(2)} total earnings waiting for approval
              </Text>
            ) : null}
            <Text>
              {pendingSentHomeRequestsCount} Pending sent home{" "}
              {pluralize("request", pendingSentHomeRequestsCount)}
            </Text>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
