import { Card, CardContent, Skeleton, Stack } from "@mui/material";

export function ShiftCardLoadingPlaceholder() {
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={2}>
          <Stack>
            <Skeleton variant="text" />
            <Skeleton variant="text" width="70%" />
          </Stack>
          <Stack>
            <Skeleton variant="text" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="50%" />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
