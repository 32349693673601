import { isDefined } from "@clipboard-health/util-ts";
import { Chip, Stack } from "@mui/material";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { type Worker } from "@src/appV2/Worker/api/types";

import { ShiftDurationWithLunchBreakIcon } from "./ShiftDurationWithLunchBreakIcon";
import { ShiftPayment } from "./ShiftPayment";
import { ShiftSchedule } from "./ShiftSchedule";
import { ShiftTimezone } from "./ShiftTimezone";

interface CardHeaderProps {
  shift: Shift;
  worker: Worker;
}

export function CardHeader(props: CardHeaderProps) {
  const { shift, worker } = props;
  // We need a Set() here because not all the licenses qualifications are unique
  const workerQualifications = worker.licensesData?.reduce((memo, licenseData) => {
    return memo.add(licenseData.qualification);
  }, new Set<string>());

  return (
    <Stack spacing={1}>
      <Stack>
        <ShiftSchedule shift={shift} />
        <ShiftDurationWithLunchBreakIcon shift={shift} timeZone={shift.facility.tmz} />
        <ShiftTimezone timeZone={shift.facility.tmz} viewer={worker} />
        <ShiftPayment shift={shift} />
      </Stack>
      <Stack spacing={0.5} alignContent="start" direction="row">
        {isDefined(workerQualifications) && workerQualifications.size > 1 && (
          <Chip size="small" label={shift.agentReq} />
        )}
        {isDefined(shift.shiftBlockId) && <Chip size="small" label="Block" />}
      </Stack>
    </Stack>
  );
}
